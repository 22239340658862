<script>
  import { onMount } from "svelte";
  import DescriptionBox from "../UI/DescriptionBox.svelte";
  import { fade } from "svelte/transition";

  export let contentData = null;

  let imageData = null;
  let folderImages = [];
  let loadImages = [];
  let currentPosition = null;
  let loaded = false;

  onMount(() => {
    folderImages = contentData.imgArray;

    folderImages.forEach((img) => {
      const imageObj = new Image();
      imageObj.src = img.src;
      imageObj.addEventListener("load", () => {
        img.loaded = true;
        handleImageLoad();
      });
    });

    imageData = contentData.file;
    currentPosition = folderImages.findIndex(
      (file) => file.id === imageData.id
    );
  });

  function handleImageLoad() {
    loaded = folderImages.every((img) => img.loaded);
  }

  function navigate(direction) {
    currentPosition = currentPosition + direction;
    if (currentPosition < 0) {
      currentPosition = folderImages.length - 1;
    }

    if (currentPosition > folderImages.length - 1) {
      currentPosition = 0;
    }

    imageData = folderImages[currentPosition];
  }

  function onKeyPress(event) {
    switch (event.keyCode) {
      case 37:
        navigate(-1);
        break;
      case 39:
        navigate(1);
        break;
    }
  }
</script>

<svelte:window on:keydown|preventDefault={onKeyPress} />

<div class="wrapper">
  {#if !loaded}
    <div class="overlay" out:fade={{ duration: 200 }}>
      <i class="fa-solid fa-circle-notch fa-spin" />
    </div>
  {/if}
  <div class="gallery-img">
    <img src={imageData?.src} alt={imageData?.name} />
  </div>
  <div class="controls">
    <i
      class="fa-solid fa-caret-right next round-border"
      on:click={() => navigate(1)}
    />
    <i
      class="fa-solid fa-caret-left previous round-border"
      on:click={() => navigate(-1)}
    />
  </div>

  <DescriptionBox>
    {imageData?.description}
  </DescriptionBox>
</div>

<style>
  .wrapper {
    height: 100%;
    overflow: hidden;
    background-color: black;
    width: 100%;
    position: relative;
  }

  .gallery-img {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gallery-img img {
    /* height: 100%; */
    max-width: 100%;
    max-height: 100%;
  }

  .controls {
    width: 100%;
    color: var(--theme-font-color);
  }

  .controls .next,
  .controls .previous {
    position: absolute;
    top: 42%;
    background-color: var(--theme-color);
    padding: 20px 10px;
    cursor: pointer;
    transition-property: background-color, box-shadow, transform;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }

  .controls .next:hover:not([disabled]),
  .controls .previous:hover:not([disabled]) {
    background-color: var(--theme-color-dark);
    color: var(--theme-font-color);
    transform: translate(3px, 3px);
    box-shadow: none;
  }

  .controls .next:hover,
  .controls .previous:hover {
    background-color: var(--theme-color-dark);
  }

  .controls .next {
    right: 10px;
  }

  .controls .previous {
    left: 10px;
  }

  .overlay {
    background-color: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay i {
    color: var(--theme-color);
    font-size: 3em;
  }
</style>
