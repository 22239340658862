<script>
  import { createEventDispatcher, onMount } from "svelte";
  import ButtonRound from "../UI/ButtonRound.svelte";
  import { theme, themes } from "../Helpers/settings-store";
  import modals from "../Helpers/modal-store";

  let themeName = $theme;
  let selectedTheme = $theme;

  export let contentData = null;
  contentData;

  updatePreview();

  function saveSettings() {
    theme.set(themeName);
    localStorage.setItem("theme", themeName);
  }

  function updatePreview() {
    selectedTheme = $themes.filter((el) => el.theme == themeName)[0];
  }
</script>

<div class="wrapper">
  <div class="preview-wrapper">
    <div
      class="preview-thumb round-border"
      style="background-image: url('{selectedTheme?.background}');"
    >
      <div class="thumb-window">
        <div
          class="thumb-window-header"
          style="background-color: {selectedTheme.color};"
        />
      </div>
    </div>
    <div class="detail">
      Photo by
      {#if selectedTheme.url}
        <a href={selectedTheme.url} target="_blank"> {selectedTheme.author} </a>
      {:else}
        {selectedTheme.author}
      {/if}
    </div>
  </div>
  <hr />
  <label for="slcTheme">Theme:</label>
  <!-- svelte-ignore a11y-no-onchange -->
  <select name="slcTheme" bind:value={themeName} on:change={updatePreview}>
    {#each $themes as item}
      <option value={item.theme}> {item.label} </option>
    {/each}
  </select>

  <div class="button-wrapper">
    <ButtonRound on:click={() => modals.closeModal("turnoff")} type="secondary"
      >Cancel</ButtonRound
    >
    <ButtonRound on:click={saveSettings}>Apply</ButtonRound>
  </div>
</div>

<style>
  .wrapper {
    font-family: "Source Sans Pro", sans-serif;
    padding: 20px;
  }

  .detail {
    font-size: 0.7em;
  }

  .preview-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .preview-thumb {
    width: 230px;
    max-width: 100%;
    height: 150px;
    background-size: cover;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
  }

  select {
    width: 100%;
  }

  label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .button-wrapper {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 15px;
  }

  .thumb-window {
    width: 110px;
    height: 70px;
    border: solid 3px #181425;
    border-radius: 5px;
    background-color: #fff8e9;
    position: absolute;
    right: 15px;
    bottom: 30px;
  }

  .thumb-window-header {
    width: 100%;
    height: 15px;
    border-bottom: solid 3px #181425;
  }
</style>
