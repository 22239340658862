<script>
  import Icon from "../UI/icon.svelte";
  import modals from "../Helpers/modal-store";
  import rootFolder from "../Helpers/folder-store";
  import { createEventDispatcher, onMount } from "svelte";
  import ButtonRound from "../UI/ButtonRound.svelte";

  const dispatch = createEventDispatcher();

  export let contentData = null;

  contentData;

  let activeSideMenu = "root";

  let folderTree = rootFolder; // folder tree changes depending on the selected sidebar item
  let currentFolder = folderTree;

  let folderHistory = ["root"];
  let historyPosition = 0;

  function loadFolderContent(fileTree, id) {
    if (fileTree.id === id) {
      return fileTree;
    }

    if (fileTree.folders) {
      for (let i = 0; i < fileTree.folders.length; i++) {
        const folder = loadFolderContent(fileTree.folders[i], id);
        if (folder) {
          return folder;
        }
      }
    }

    return null;
  }

  function onClickFolder(folder) {
    currentFolder = folder;
    historyPosition = historyPosition + 1;
    resetHistory(0, historyPosition);
    folderHistory.push(folder.id);
  }

  function onClickFile(file) {
    if (file.type == "img") {
      let data = {
        file: file,
        imgArray: currentFolder.files.filter((file) => file.type == "img"),
      };
      dispatch("fileClick", data);
      openModal("gallery");
      return;
    }

    if (file.type == "exe") {
      let data = file;
      dispatch("fileClick", data);
      openModal("exe");
      return;
    }
  }

  function openModal(id) {
    let modal = { ...$modals.find((element) => element.id === id) };
    modals.openModal(id);
  }

  function navigate(direction) {
    historyPosition = historyPosition + direction;
    if (
      folderHistory[historyPosition] == "starred" ||
      folderHistory[historyPosition] == "recent"
    ) {
      onClickSidebar(folderHistory[historyPosition], true);
      return;
    }

    currentFolder = loadFolderContent(
      folderTree,
      folderHistory[historyPosition]
    );
  }

  function onClickSidebar(item, isNav = false) {
    activeSideMenu = item; // activates the sidebar item

    // If is back/forward navigation
    if (isNav == false) {
      if (folderHistory[historyPosition] == item) {
        return;
      }
      historyPosition = historyPosition + 1;
      resetHistory(0, historyPosition);
      folderHistory.push(item);
    }

    if (item == "root") {
      currentFolder = rootFolder;
      return;
    }

    let filteredFolders = filterFoldersByTags(rootFolder, item);
    currentFolder = {
      id: item,
      name: item,
      folders: [...filteredFolders],
      files: [],
    };

    currentFolder.files = filterFilesByTags(rootFolder, item);
  }

  function filterFoldersByTags(rootFolder, tags) {
    let filteredFolders = [];
    if (rootFolder.tags.some((tag) => tags.includes(tag))) {
      filteredFolders.push(rootFolder);
    }
    if (rootFolder.folders.length > 0) {
      rootFolder.folders.forEach((folder) => {
        const foldersWithTags = filterFoldersByTags(folder, tags);
        if (foldersWithTags.length > 0) {
          filteredFolders = [...filteredFolders, ...foldersWithTags];
        }
      });
    }
    return filteredFolders;
  }

  function filterFilesByTags(rootFolder, tags) {
    let result = [];

    function traverse(folder) {
      folder.folders.forEach((f) => traverse(f));
      folder.files.forEach((f) => {
        if (f.tags.includes(tags)) {
          result.push(f);
        }
      });
    }

    traverse(rootFolder);
    return result;
  }

  function resetHistory(begin, end) {
    if (folderHistory.length >= historyPosition + 1) {
      folderHistory = folderHistory.slice(begin, end);
    }
  }
</script>

<div class="folder-wrapper">
  <div class="sidebar">
    <div class="sidebar-buttons">
      <ButtonRound
        small={true}
        disabled={historyPosition == 0}
        on:click={() => navigate(-1)}
      >
        <i class="fa-solid fa-arrow-left fa-fw" />
      </ButtonRound>
      <ButtonRound
        small={true}
        disabled={historyPosition >= folderHistory.length - 1}
        on:click={() => navigate(1)}
      >
        <i class="fa-solid fa-arrow-right fa-fw" />
      </ButtonRound>
    </div>
    <ul class="sidebar-menu">
      <li
        class="sidebar-menu-item"
        class:active={activeSideMenu == "root"}
        on:click={() => onClickSidebar("root")}
      >
        <i class="far fa-folder" />
        <span class="menu-item-label"> Root Folder </span>
      </li>
      <li
        class="sidebar-menu-item"
        class:active={activeSideMenu == "starred"}
        on:click={() => onClickSidebar("starred")}
      >
        <i class="far fa-star" />
        <span class="menu-item-label"> Starred </span>
      </li>
      <li
        class="sidebar-menu-item"
        class:active={activeSideMenu == "recent"}
        on:click={() => onClickSidebar("recent")}
      >
        <i class="far fa-clock" />
        <span class="menu-item-label"> Recent </span>
      </li>
    </ul>
  </div>

  <div class="folder-content-wrapper">
    <div class="folder-title">
      <h3>
        {currentFolder.name}
      </h3>
    </div>
    <div class="folder-content">
      {#if currentFolder.folders.length > 0}
        {#each currentFolder.folders as folder, i}
          <Icon icon={folder.icon} on:dblclick={() => onClickFolder(folder)}>
            {folder.name}
          </Icon>
        {/each}
      {/if}
      {#if currentFolder.files.length > 0}
        {#each currentFolder.files as file, i}
          <Icon icon={file.icon} on:dblclick={() => onClickFile(file)}>
            {file.name}.{file.type}
          </Icon>
        {/each}
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .folder-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .sidebar {
    background-color: #eee;
    flex-basis: 150px;
    flex-shrink: 0;
    border-right: solid 2px var(--theme-color);
  }

  ul.sidebar-menu {
    list-style-type: none;
    padding-left: 0;
  }

  ul.sidebar-menu li.sidebar-menu-item {
    font-size: 0.8em;
    padding: 10px;
    cursor: pointer;
    font-family: "Source Sans Pro", sans-serif;

    &:hover {
      background-color: #e3e3e3;
    }

    &:active,
    .active {
      background-color: var(--theme-color);
      color: var(--theme-font-color);
    }
  }

  ul.sidebar-menu li.sidebar-menu-item:active,
  ul.sidebar-menu li.sidebar-menu-item.active {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
  }

  .sidebar-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 15px;
    gap: 7px;
  }

  .menu-item-label {
    padding-left: 7px;
    font-weight: 500;
  }

  .folder-content-wrapper {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }

  .folder-content {
    padding: 10px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fit, 119px);
    width: 100%;
  }

  .folder-title {
    padding: 10px;
    font-weight: bold;
    text-transform: capitalize;
  }
</style>
