<script>
  export let disabled = false;
  export let type = "primary";
  export let small = false;
  export let circle = false;
</script>

<button
  class="round-border"
  class:secondary={type === "secondary"}
  class:circle
  class:small
  on:click
  {disabled}
>
  <slot />
</button>

<style lang="scss">
  button {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
    transition-property: transform, box-shadow;
    transition-duration: 0.2s;
    cursor: pointer;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    box-sizing: content-box;
    padding: 7px 10px;
    font-size: 0.8em;
    margin: 10px 5px;
  }

  button:hover:not([disabled]) {
    // background-color: #f9acc5;
    background-color: var(--theme-color-dark);
    color: var(--theme-font-color);
    transform: translate(3px, 3px);
    box-shadow: none;
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .secondary {
    background-color: #fff8e9;
    color: #181425;
  }

  .circle {
    border-radius: 100px;
    padding: 13px 18px;
    font-size: 1.2em;
  }

  .small {
    padding: 4px 5px;
  }
</style>
