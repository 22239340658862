<script>
  export let refresh = null;
  export let src = null;

  src;
  refresh;
</script>

<div class="container">
  <div class="banner-wrapper round-border">
    <div class="banner-content round-border">
      <h1>Hello, World!</h1>
      <h4>Let's connect!</h4>
    </div>
  </div>
  <div class="contact-wrapper">
    <h3>Jéssica Fraga</h3>
    <hr />
    <p>Find me through my social media below! (More to come soon)</p>
    <div class="social-wrapper">
      <a
        href="https://www.linkedin.com/in/j%C3%A9ssica-fraga-325940130/"
        target="_blank"
        class="social-icon"
      >
        <i class="fa-brands fa-linkedin" />
      </a>
      <a
        href="https://github.com/iKuroiDev"
        class="social-icon"
        target="_blank"
      >
        <i class="fa-brands fa-github" />
      </a>
    </div>
    <p>
      Or fly me an e-mail!
      <a href="mailto:hello@kuroi.com.br"
        ><i class="far fa-paper-plane" /> hello@kuroi.com.br</a
      >
    </p>
  </div>
</div>

<style>
  .contact-wrapper {
    margin-bottom: 30px;
  }

  .social-icon {
    font-size: 2.5em;
    padding-right: 10px;
  }

  .social-wrapper {
    padding-bottom: 20px;
  }

  .banner-content {
    background-color: #fff8e9;
    border-radius: 9999px;
    padding: 20px 30px;
    width: max-content;
    text-align: center;
  }
  .banner-wrapper {
    margin: 20px 0;
    width: 100%;
    padding: 4em 1em;
    display: flex;
    justify-content: center;
    background-color: #fff8e9;
    background-image: linear-gradient(
        30deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        150deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        30deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        150deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        60deg,
        var(--theme-color-dark) 25%,
        transparent 25.5%,
        transparent 75%,
        var(--theme-color-dark) 75%,
        var(--theme-color-dark)
      ),
      linear-gradient(
        60deg,
        var(--theme-color-dark) 25%,
        transparent 25.5%,
        transparent 75%,
        var(--theme-color-dark) 75%,
        var(--theme-color-dark)
      );
    background-size: 80px 140px;
    background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
  }
</style>
