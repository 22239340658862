<script>
  import { onMount } from "svelte";
  import Desktop from "./Desktop.svelte";
  import LoadOs from "./Programs/LoadOS.svelte";
  import TurnoffOverlay from "./Programs/turnoffOverlay.svelte";
  import { theme, themes } from "./Helpers/settings-store";

  let loaded = false;
  let turnoff = false;

  let firstVisit = true;
  let today = new Date();
  let localTheme = localStorage.theme;
  let selectedTheme = "";

  theme.subscribe((value) => {
    selectedTheme = $themes.filter((theme) => theme.theme == value)[0];
  });

  onMount(() => {
    if (localTheme) {
      selectedTheme = $themes.filter((theme) => theme.theme == localTheme)[0];
      theme.set(localTheme);
      return;
    }

    selectedTheme = $themes.filter(
      (theme) => theme.theme == "theme-pinkretro"
    )[0];
  });

  function setExpiryDate(date) {
    let expiryDate = new Date(date);
    expiryDate.setDate(expiryDate.getDate() + 7);
    localStorage.setItem("expiryDate", expiryDate);
  }

  function checkExpiryDate() {
    let storageExpiryDate = localStorage.getItem("expiryDate");
    if (!storageExpiryDate) {
      setExpiryDate(today);
      return;
    }

    if (storageExpiryDate && today < new Date(storageExpiryDate)) {
      firstVisit = false;
    } else {
      firstVisit = true;
      setExpiryDate(today);
    }
  }

  function onTurnoff() {
    turnoff = true;
  }

  checkExpiryDate();

  let preloadImageUrls = [
    "./img/icons/browser.png",
    "./img/icons/directory.png",
    "./img/icons/exe.png",
    "./img/icons/photo.png",
  ];
</script>

<svelte:head>
  {#each preloadImageUrls as image}
    <link rel="preload" as="image" href={image} />
  {/each}
</svelte:head>

<div
  style="position:relative;
  height:100%;
--theme-color:{selectedTheme.color};
--theme-color-dark:{selectedTheme.colorDark};
--theme-font-color:{selectedTheme.fontColor};
--theme-wallpaper: url('{selectedTheme.background}');
"
>
  {#if firstVisit && !loaded}
    <LoadOs on:loaded={() => (loaded = true)} />
  {/if}
  <Desktop on:turnoff={onTurnoff} />
  {#if turnoff}
    <TurnoffOverlay />
  {/if}
</div>
