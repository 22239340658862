import { writable } from 'svelte/store';
import Browser from '../Programs/browser.svelte';
import Settings from '../Programs/Settings.svelte';
import Turnoff from '../Programs/turnoff.svelte';
import Folder from '../Programs/folder.svelte';
import Gallery from '../Programs/gallery.svelte';
import Exe from '../Programs/Exe.svelte';

const modals = writable([]);
export let emphasis = writable();
const modalsConfig = [
	{
		id: 'browser',
		name: 'KuroiFox Browser',
		height: 500,
		width: 500,
		component: Browser,
		resizable: true,
		maximizable: true,
		multipleWindows: true,
		icon:'fa-solid fa-globe'
	},
	{
		id: 'folder',
		name: 'KuroiOS Explorer',
		height: 500,
		width: 510,
		component: Folder,
		resizable: true,
		maximizable: true,
		multipleWindows: true,
		icon: 'fa-regular fa-folder-open'
	},
	{
		id: 'gallery',
		name: 'Photo Viewer',
		height: 500,
		width: 510,
		component: Gallery,
		resizable: true,
		maximizable: true,
		multipleWindows: true,
		icon: 'fa-regular fa-image'
	},
	{
		id: 'config',
		name: 'Settings',
		height: 550,
		width: 300,
		component: Settings,
		resizable: false,
		maximizable: false,
		multipleWindows: false,
		icon: 'fa-solid fa-gear'
	},
	{
		id: 'turnoff',
		name: 'Turn off the system?',
		height: 250,
		width: 500,
		component: Turnoff,
		resizable: false,
		maximizable: false,
		multipleWindows: false,
		icon: 'fa-solid fa-power-off'
	},
	{
		id: 'exe',
		name: 'Running exe',
		height: 500,
		width: 500,
		component: Exe,
		resizable: true,
		maximizable: true,
		multipleWindows: true,
		icon: 'fa-solid fa-display'
	},
];

const customModalStore = {
	subscribe: modals.subscribe,
	closeModal: (key) => {
		modals.update((items) => {
			let currentModals = items;
			const index = currentModals.findIndex((element) => element.key === key);
			if(index > -1) {
				currentModals.splice(index, 1)				
			}
			return currentModals;
		});
	},
	openModal: (id, shortcut=null) => {
		modals.update((items) => {
			let openWindows = [...items];
			let windowToOpen = {... modalsConfig.find((element) => element.id === id)}
			
			// Checks if multiple windows is allowed and if there's a window already opened
			if(!windowToOpen.multipleWindows && openWindows.some(element => element.id == windowToOpen.id)) {
				emphasis.set(windowToOpen.id);
				return openWindows;
			}			
			// Generates a unique id for the window to be opened
			windowToOpen.key = windowToOpen.id + '-' + Math.floor(Math.random()*10000);

			if (shortcut) {
				windowToOpen.shortcut = shortcut
			}
			
			
			//pushes the new window to the array
			openWindows = [windowToOpen, ...items];
			return openWindows;
		});
	},
};

export default customModalStore;
