<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { scale, fade } from "svelte/transition";
  import { emphasis } from "../Helpers/modal-store";

  export let modalData;

  let dispatch = createEventDispatcher();

  emphasis.subscribe((element) => {
    if (element) {
      let openWindows = Array.from(document.getElementsByClassName("window"));
      let windowToEmphasise = openWindows.find(
        (window) => window.id.split("-")[0] == element
      );

      let maxZIndex = Math.max(...openWindows.map((el) => el.style.zIndex));
      windowToEmphasise.style.zIndex = maxZIndex + 1;
      document.querySelector("#handle" + element).classList.add("blink");
      setTimeout(() => {
        document.querySelector("#handle" + element).classList.remove("blink");
      }, 1000);
      emphasis.set(null);
    }
  });

  onMount(() => {
    jQuery(".window").resizable({
      handles: "se",
      minHeight: modalData.height,
      minWidth: modalData.width,
      containment: "main",
      disabled: !modalData.resizable,
    });
    jQuery(".window").draggable({
      handle: ".title-bar",
      containment: "main",
      scroll: false,
      disabled: maximized,
      stack: ".window",
    });

    bringToFront();

    // define a posiçao em relação as outras janelas
    let openWindows = Array.from(document.getElementsByClassName("window"));
    let currentWindow = document.getElementById(modalData.key);

    if (modalData.id !== "turnoff") {
      if (openWindows.length > 1) {
        currentWindow.style.top =
          parseInt(openWindows[1].style.top.slice(0, -2)) + 10 + "px";
        currentWindow.style.left =
          parseInt(openWindows[1].style.left.slice(0, -2)) + 10 + "px";
      } else if (openWindows.length <= 1) {
        currentWindow.style.top =
          parseInt(openWindows[0].style.top.slice(0, -2)) + 10 + "px";
        currentWindow.style.left =
          parseInt(openWindows[0].style.left.slice(0, -2)) + 10 + "px";
      }
    } else {
      currentWindow.style.top = "0";
      currentWindow.style.left = "0";
      currentWindow.style.right = "0";
      currentWindow.style.bottom = "0";
      currentWindow.style.margin = "auto";
      jQuery("#" + currentWindow.id).draggable("disable");
    }

    openWindows.sort(function (a, b) {
      if (a.style.zIndex > b.style.zIndex) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  });

  function bringToFront() {
    let openWindows = Array.from(document.getElementsByClassName("window"));
    let currentWindow = document.getElementById(modalData.key);
    let maxZIndex = Math.max(...openWindows.map((el) => el.style.zIndex));
    currentWindow.style.zIndex = maxZIndex + 1;
  }

  let maximized = false;

  function toggleMaximize() {
    maximized = !maximized;
  }
</script>

<div
  class:window-maximized={maximized}
  class:center-window={modalData.id === 3}
  class="window round-border ui-widget-content"
  in:scale={{ duration: 300 }}
  out:scale={{ duration: 300 }}
  style={`width: ${modalData.width}px; height: ${modalData.height}px; top: 30px; left: 30px;`}
  id={modalData.key}
  on:click={bringToFront}
>
  <div class="title-bar" id="handle{modalData.id}">
    <div class="title-bar-name">
      <i class={modalData.icon} />
      {modalData.name}
    </div>
    <div class="title-bar-button">
      {#if modalData.maximizable}
        <button on:click={toggleMaximize}>
          {#if maximized}
            <i class="far fa-window-restore" />
          {:else}
            <i class="far fa-window-maximize" />
          {/if}
        </button>
      {/if}
      <button on:click={() => dispatch("close", modalData.id)}>
        <i class="fas fa-times" />
      </button>
    </div>
  </div>
  <div class="window-content">
    <slot />
    <br /><br />
  </div>
  <div class="window-footer">
    <div class="footer-text" />
    {#if modalData.resizable && !maximized}
      <div class="footer-grip">
        <img src="img/resize-handle.png" alt="" />
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .window {
    /* border: solid 3px #181425; */
    color: #181425;
    background-color: var(--theme-color);
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    transition: width 0.2s, height 0.2s;
  }

  .window-content {
    background-color: #fff8e9;
    flex: 1;
    overflow: hidden;
    scrollbar-width: auto;
    scrollbar-color: var(--theme-color) #fff;
    position: relative;
  }

  .window-footer {
    height: 20px;
    border-top: solid 2px #181425;
    font-size: 0.5em;
    padding: 3px 10px;
    font-family: "Comfortaa", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--theme-font-color);
  }

  .window-maximized {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
  }

  .footer-grip img {
    height: 10px;
    opacity: 0.5;
  }

  /*scrollbar */

  .window-content::-webkit-scrollbar {
    width: auto;
  }
  .window-content::-webkit-scrollbar-track {
    background: #fff;
    /* border: solid 2px #181425; */
  }
  .window-content::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background: var(--theme-color);
    width: 10px;
    border-radius: 8px;
  }

  /* TITLE BAR */
  .title-bar {
    height: 40px;
    border-bottom: solid 3px #181425;
    background-color: var(--theme-color);
    padding: 6px 10px;
    border-radius: 10px 10px 0 0;
    font-family: "Comfortaa", sans-serif;
    font-weight: bold;
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-bar-name {
    color: var(--theme-font-color);
  }

  .title-bar-button {
    height: 100%;
  }

  .title-bar-button button {
    border: solid 3px#181425;
    border-radius: 5px;
    height: 25px;
    width: 25px;
    padding: 0;
    background-color: rgba(256, 256, 256, 0.5);
    transition: background-color 0.25s;
    font-weight: bold;
    line-height: 0;
    font-size: 0.8em;
  }

  .title-bar-button button:hover {
    background-color: var(--theme-color-dark);
    cursor: pointer;
  }

  .center-window {
    top: 50% !important; /* position the top  edge of the element at the middle of the parent */
    left: 50% !important; /* position the left edge of the element at the middle of the parent */

    transform: translate(
      -50%,
      -50%
    ); /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
  }

  :is(.blink) {
    animation: blinker 0.2s linear infinite;
  }

  @keyframes blinker {
    50% {
      background-color: var(--theme-color-dark);
    }
  }
</style>
