<script>
  export let image;
  export let tags = ["Svelte", "HTML", "CSS"];
  export let disabled = false;
</script>

<div class="card-wrapper round-border" class:disabled on:click>
  <div class="card-thumbnail">
    <img src={"img/WebXp/" + image} alt="App thumbnail" />
    <div class="tags">
      {#each tags as tag}
        <span class="tag">{tag}</span>
      {/each}
    </div>
    {#if disabled}
      <div class="coming-soon">WIP</div>
    {/if}
  </div>
  <div class="card-description">
    <slot />
  </div>
</div>

<style>
  .card-wrapper {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    transition-property: transform, box-shadow;
    transition-duration: 0.2s;
  }

  .card-wrapper.disabled img {
    filter: grayscale(100%);
  }

  .card-wrapper:not(.disabled):hover {
    transform: translate(3px, 3px);
    box-shadow: none;
    cursor: pointer;
  }

  .card-thumbnail {
    position: relative;
    overflow: hidden;
  }

  .card-thumbnail img {
    width: 100%;
    height: 170px;
    object-fit: cover;
  }

  .card-description {
    padding: 10px 10px;
    font-size: 0.8em;
    font-family: "Source Sans Pro", sans-serif;
  }

  .tags {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .tag {
    font-size: 0.7em;
    background-color: #fff;
    border: 2px solid var(--theme-color-dark);
    border-radius: 50px;
    color: var(--theme-color-dark);
    padding: 2px 4px;
    margin-right: 5px;
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
  }

  .coming-soon {
    background-color: var(--theme-color-dark);
    border: solid 3px #181425;
    transform: rotate(45deg);
    position: absolute;
    right: -21px;
    top: 6px;
    color: var(--theme-font-color);
    font-family: "Comfortaa";
    font-weight: 700;
    font-size: 0.7em;
    padding: 0 2em;
  }
</style>
