<script>
  import { pages } from "../Helpers/browser-store";
  import ButtonRound from "../UI/ButtonRound.svelte";
  import { onMount } from "svelte";

  export let exportPage = "page_home";
  let page = "";
  let url = "";
  let history = [];
  let refresh = null;

  export let contentData = null;

  contentData;

  onMount(() => {
    page = exportPage;
    findPage(page);
    history = [page.key];
  });

  function findPage(pageName) {
    page = $pages.filter((p) => p.key == pageName)[0];
    url = page.url;
    refresh = null;
  }

  function goTo(pageName) {
    if (pageName == history.slice(-1)) {
      return;
    }
    findPage(pageName);
    history = [...history, page.key];
  }

  function goBack() {
    history.pop();
    history = history;
    findPage(history.slice(-1));
    return;
  }

  function onRefresh() {
    refresh = !refresh;
  }

  function goToUrlByAddress() {
    let toPage = $pages.filter((p) => p.url == url)[0];
    if (toPage) {
      goTo(toPage.key);
    } else {
      goTo("page_404");
    }
    jQuery("#address").blur();
  }
</script>

<div class="browser-wrapper">
  <div class="address-bar">
    <ButtonRound disabled={history.length <= 1} on:click={goBack}>
      <i class="fas fa-arrow-left" />
    </ButtonRound>
    <ButtonRound on:click={() => goTo("page_home")}>
      <i class="fas fa-home" />
    </ButtonRound>
    <ButtonRound on:click={onRefresh}>
      <i class="fa-solid fa-rotate-right" />
    </ButtonRound>

    <div class="address-input">
      <form on:submit|preventDefault={goToUrlByAddress}>
        <input
          type="text"
          bind:value={url}
          id="address"
          placeholder="Type an address"
        />
      </form>
    </div>
  </div>
  <div class="browser-content">
    <svelte:component
      this={page?.component}
      on:goTo={(event) => goTo(event.detail)}
      src={page?.src}
      {refresh}
    />
  </div>
</div>

<style>
  .browser-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .address-bar {
    background-color: var(--theme-color-dark);
    border-bottom: solid 2px #181425;
    display: flex;
    padding: 0 10px;
    align-items: center;
  }

  .address-input {
    width: 100%;
    border-radius: 50px;
    border: solid 3px #181425;
    margin: 10px;
    background-color: #fff8e9;
    display: flex;
    align-items: center;
  }

  .address-input input {
    background-color: transparent;
    border: none;
    width: 100%;
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 0.8em;
    padding: 0.4em 15px;
    color: #181425;
  }

  .address-input input:focus {
    outline: none;
  }

  .browser-content {
    /* height: 100%; */
    overflow-y: auto;
    flex-grow: 1;
  }
</style>
