import { writable, readable } from 'svelte/store';
import Text3D from '../Content/ExeFiles/3dText.svelte'
import Gui from '../Content/ExeFiles/Gui.svelte'

/*
  Base Structure:
  id: string,
  name: string,
  icon: string,
  type: string, ('folder' or 'file')
  tags: [string] ('recent', 'starred' )
  files: [
    id: string,
    component: Component OR src:'filepath',
    icon: string,
    name: string
  ]
  folders: [same as base structure, repeat]
 */

const rootFolder = 
    {
        id: 'root',
        name: 'Root',
        tags: [],
        type: 'folder',
        icon: 'directory',
        folders: [
            {
                id: 'illustration',
                name: 'Illustrations',
                tags: [
                    'recent',
                ],
                type: 'folder',
                icon: 'directory',
                files: [
                    {
                        id: 'kuroneko1',
                        type:'img',
                        src: 'img/Pictures/Illustrations/kuroneko.jpg',
                        icon: 'photo',
                        name: 'Kuroneko',
                        tags: ['starred'],
                        description: 'This is a character created in collaboration with my boyfriend. Her name is Kuroneko and we have plans on creating a game featuring her!'
                    },
                    {
                        id: 'kuroneko2',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/kuroneko_nya.png',
                        icon: 'photo',
                        name: 'Kuroneko2',
                        tags: ['starred'],
                        description: 'Another Kuroneko depiction :)'
                    },
                    {
                        id: 'kuroneko3',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/pixel_kuroneko.png',
                        icon: 'photo',
                        name: 'Kuroneko Pixel',
                        tags: [],
                        description: 'Another Kuroneko artwork in pixel art.'
                    },
                    {
                        id: 'machio',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/machio_san.jpg',
                        icon: 'photo',
                        name: 'Machio san',
                        tags: [],
                        description: 'This was one of the entries I made for an Inktober, featuring Machio from the Dumbbell Nan Kilo Moteru? anime.'
                    },
                    {
                        id: 'wisp',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/wisp.jpg',
                        icon: 'photo',
                        name: 'Wisp',
                        tags: [],
                        description: 'Another Inktober entry, featuring Wisp from Animal Crossing.'
                    },
                    {
                        id: 'dragon',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/dragon.png',
                        icon: 'photo',
                        name: 'Crystal Dragon',
                        tags: ['recent'],
                        description: "This dragon was a commission for a client who took part in a DnD group. It was very challenging as I'm not used to drawing dragons, but the result was a success!"
                    },
                    {
                        id: 'jessanthia',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/Jessanthia_chibi.png',
                        icon: 'photo',
                        name: 'Jessanthia',
                        tags: ['recent'],
                        description: "Commission for Twitch streamer Jessanthia."
                    },
                    {
                        id: 'sculpted1',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/sculpted.png',
                        icon: 'photo',
                        name: 'Sculpted',
                        tags: ['recent'],
                        description: "Commission for Twitch streamer Sculpted."
                    },
                    {
                        id: 'sculpted2',
                        type: 'img',
                        src: 'img/Pictures/Illustrations/sculpted_rise.png',
                        icon: 'photo',
                        name: 'Sculpted - MH Rise',
                        tags: ['recent', 'starred'],
                        description: "Commission for Twitch streamer Sculpted. Updated chibi following his Monster Hunter Rise character."
                    },
                ],
                folders: []
            },
            {
                id:'3d',
                name: '3D',
                icon: 'directory',
                tags: [],
                type: 'folder',
                files: [
                    {
                        id: '3dtext',
                        type:'exe',
                        isIframe: false,
                        src: Text3D,
                        icon: 'exe',
                        name: '3D Hello World',
                        tags: ['recent', 'starred'],
                        description: "Simple 3D Text based on Bruno Simon's course (Three.js journey)."
                    },
                ],
                folders: [
                    {
                        id:'renders',
                        name: 'Renders',
                        icon: 'directory',
                        tags: [],
                        type: 'folder',
                        files: [
                            {
                                id: 'mountain',
                                type:'img',
                                src: 'img/Pictures/3Drenders/mountain.png',
                                icon: 'photo',
                                name: 'Low-poly mountain',
                                tags: [],
                                description: 'A low-poly mountain made quite some time ago using Blender.'
                            },
                            {
                                id: 'taiko',
                                type:'img',
                                src: 'img/Pictures/3Drenders/taiko.png',
                                icon: 'photo',
                                name: 'Taiko',
                                tags: ['starred'],
                                description: 'Don-chan from Taiko no Tatsujin game series.'
                            },
                        ],
                        folders: []
                    }
                ]
        
            }
        ],
        files: [

        ],
    };


export default rootFolder;