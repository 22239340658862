<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from "svelte/transition";

  onMount(() => {});
</script>

<div class="wrapper" in:fade={{ duration: 600 }}>
  <h2 in:fade={{ duration: 500, delay: 500 }}>
    It's now safe to turn off your computer.
  </h2>
  <h6 in:fade={{ duration: 500, delay: 2000 }}>
    Maybe something interesting could happen now in the "real world"...
  </h6>
</div>

<style>
  .wrapper {
    height: 100%;
    width: 100%;
    background-color: black;
    padding: 10px;
    color: var(--theme-color);
    text-shadow: 0px 0px 8px var(--theme-color);
    font-family: "Perfect DOS";
    font-size: 1.2em;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .wrapper::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        rgba(18, 16, 16, 0) 50%,
        rgba(0, 0, 0, 0.25) 50%
      ),
      linear-gradient(
        90deg,
        rgba(255, 0, 0, 0.06),
        rgba(0, 255, 0, 0.02),
        rgba(0, 0, 255, 0.06)
      );
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }

  h2,
  h6 {
    text-align: center;
  }

  h6 {
    margin-top: 20px;
  }
</style>
