<script>
  import { onMount, onDestroy } from "svelte";
  import { theme, themes } from "../../Helpers/settings-store";
  import { fade } from "svelte/transition";
  import ButtonRound from "../../UI/ButtonRound.svelte";

  export let src = null;
  src;

  let iframe = null;
  let isLoading = true;
  let iframeWindow = null;
  export let refresh = null;

  $: onRefresh(refresh);

  function sendIframeMessage() {
    let filtered = $themes.filter((element) => element.theme == $theme)[0];
    let data = {};
    data.color = filtered.color;
    data.colorDark = filtered.colorDark;
    data.fontColor = filtered.fontColor;
    iframeWindow.postMessage(data, "*");
  }

  function preloader() {
    iframe.addEventListener("load", function () {
      isLoading = false;
    });
  }

  onMount(() => {
    iframe = document.querySelector("iframe");
    preloader();
    iframeWindow = document.querySelector("iframe").contentWindow;
    window.addEventListener("message", (event) => {
      if (event.data == "contentChanged") {
        sendIframeMessage();
      }
    });
  });

  function onRefresh(ref) {
    if (ref !== null) {
      jQuery("iframe").attr("src", jQuery("iframe").attr("src"));
      preloader();
    }
  }
</script>

<div class="content">
  {#if isLoading}
    <div class="overlay" out:fade={{ duration: 200 }}>
      <i class="fa-solid fa-circle-notch fa-spin" />
    </div>
  {/if}
  <iframe
    seamless
    title="kuroi-blog"
    src="https://blog.kuroi.com.br"
    frameborder="0"
  />
  <div class="redirect-button-container">
    <ButtonRound circle={true} on:click={() => window.open(src, "_blank")}>
      <i class="fa-solid fa-arrow-up-right-from-square" />
    </ButtonRound>
  </div>
</div>

<style>
  .content {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .overlay {
    background-color: rgba(256, 256, 256, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay i {
    color: var(--theme-color);
    font-size: 3em;
  }

  .redirect-button-container {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }
</style>
