<script>
  let toggleDetails = false;

  const onClickToggleDetails = () => {
    toggleDetails = !toggleDetails;
    jQuery("#detail-paragraph").slideToggle();
  };
</script>

{#if $$slots.default}
  <div class="details">
    <i
      class="toggle-details fa-solid fa-chevron-{toggleDetails ? 'down' : 'up'}"
      on:click={onClickToggleDetails}
    />
    <p id="detail-paragraph">
      <slot />
    </p>
  </div>
{/if}

<style>
  .details {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .details p {
    background-color: #fff8e9;
    color: #181425;
    padding: 15px;
    font-size: 0.8em;
    margin-bottom: 0;
    border-top: solid 4px #181425;
    display: none;
  }

  .toggle-details {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
    padding: 5px 15px 5px 15px;
    margin-left: 10px;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    position: absolute;
    border: solid 4px #181425;
    top: -32px;
  }

  .toggle-details:hover {
    background-color: var(--theme-color-dark);
  }
</style>
